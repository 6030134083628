const BlockCont = () => {
    return (
        <section className="w-[100%] flex justify-center h-auto my-10">
            <div className="w-4/5 h-auto items-center flex justify-between  md:flex-row flex-col-reverse">
                <div className="w-full md:w-[45%] h-auto py-10 space-y-5 items-center flex flex-col text-center md:text-start bg-slate-50 shadow-xl">
                    <h4 className="mx-auto">We Also have Financing Available</h4>
                    <div className="w-full md:pl-8 flex justify-center md:justify-start">
                        <div className="w-[25%] py-2 bg-red-700  text-center rounded-xl text-white">
                            <a href="https://impruvu.io/westrichlandconcrete/" target="_blank" rel="noreferrer">
                                <h6> MORE </h6>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="md:w-[45%] mt-4 md:mt-0">
                    <img
                        src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/logo_seo-1.png?alt=media&token=342b3fd3-4bc4-415f-91b1-e1111a217a02"}
                        alt='no found'
                        loading='lazy'
                        className='w-full object-cover'
                    />
                </div>
            </div>
        </section>
    );
}

export default BlockCont;